import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadForm = ({ user, type }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDone, setIsDone] = useState(false); // New state to track if the user is done
    // const SERVER_URL = 'http://localhost:3000/';

  const SERVER_URL = 'https://www.camup.live/';

  const fileUrl = `${SERVER_URL}upload`;

  const notifyError = (message) => toast.error(message);  // Function to display error notification

  const uploadFile = async (file) => {
    const newFile = { name: file.name, status: 'uploading', size: file.size, progress: 0 };
    setUploadedFiles(prevFiles => [...prevFiles, newFile]);
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', user?.email);
    formData.append('uploadType', type.charAt(0).toUpperCase() + type.slice(1));
  
    try {
      await axios.post(fileUrl, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (progress < 100) {
            setUploadedFiles(prevFiles =>
              prevFiles.map(f => f.name === file.name ? { ...f, progress } : f)
            );
          }
        }
      });

      setUploadedFiles(prevFiles =>
        prevFiles.map(f => f.name === file.name ? { ...f, status: 'success', progress: 100 } : f)
      );
    } catch (error) {
      setUploadedFiles(prevFiles =>
        prevFiles.map(f => f.name === file.name ? { ...f, status: 'error' } : f)
      );
      const errorMessage = error.response?.data?.error || 'Failed to upload file';
      notifyError(errorMessage);
    }
  };

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith('.m4a')) {
        notifyError('No m4a files are allowed.');
        return;
      }
      uploadFile(selectedFile);
    }
  };

  const removeFileHandler = async (fileName) => {
    const file = uploadedFiles.find((f) => f.name === fileName);
    if (file && file.status === 'error') {
      setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.name !== fileName));
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}deleteFile`, {
        fileName,
        user: user?.email,
      });

      if (response.status === 200) {
        setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.name !== fileName));
      } else {
        notifyError('Failed to delete the file from the server.');
      }
    } catch (error) {
      notifyError('Error deleting file.');
    }
  };


  const handleDone = async () => {
    if (window.confirm('Are you sure these are the songs you want to submit?')) {
      try {
        await axios.post(`${SERVER_URL}auth/setGuidToNull`, {
          user: user?.email,
        });
        setIsDone(true);
      } catch (error) {
        notifyError('Error Submitting Music. Please try again or contact support at support@lightenergylabs.xyz.');
      }
    }
  };

  return (
    <div className="upload-page">
      <ToastContainer 
        theme="dark"
        position="top-center"
      />
      <img src='https://uploads-ssl.webflow.com/641b56cbb2396a6318364e55/644991a8c2c53942bd0416d6_Timbo-Idol_Primary%20Mark.png' className='logo' />
        {isDone ? (
          <div>
            <h2>Thank you for uploading your music!</h2>
            <p>Your submission has been received.</p>
            <p>Check your email for your personal link to CamUP! with Timbaland.</p>
          </div>
        ) : (
        <>
          <h1>Upload Your Music</h1>
          <form className="custom-file-upload-form">
            <label htmlFor="file-upload" className="custom-file-upload">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#3e8cac" fill="none">
                  <path d="M12.9995 21H11.9995C7.28547 21 4.92844 21 3.46398 19.5355C1.99951 18.0711 1.99951 15.714 1.99951 11V7.94427C1.99951 6.1278 1.99951 5.21956 2.37983 4.53806C2.65093 4.05227 3.05178 3.65142 3.53757 3.38032C4.21907 3 5.12731 3 6.94378 3C8.10753 3 8.68941 3 9.19877 3.19101C10.3617 3.62712 10.8413 4.68358 11.3661 5.73313L11.9995 7M7.99951 7H16.7495C18.8562 7 19.9096 7 20.6662 7.50559C20.9938 7.72447 21.275 8.00572 21.4939 8.33329C21.9791 9.05942 21.9987 10.0588 21.9995 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M19.3966 18.7446C19.3966 19.9885 18.4123 20.9969 17.198 20.9969C15.9838 20.9969 14.9995 19.9885 14.9995 18.7446C14.9995 17.5007 15.9838 16.4923 17.198 16.4923C18.4123 16.4923 19.3966 17.5007 19.3966 18.7446ZM19.3966 18.7446V13C19.3966 13 20.7036 15.6999 22.0005 15.6999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>
              <div><strong>Click Here</strong> to Browse & Upload Music</div>
              <span className="custom-upload-small-text">Only MP3 & WAV Files</span>
              </span>
            
            </label>
            <input id="file-upload" type="file" accept=".mp3,.wav" onChange={fileChangedHandler} style={{ display: 'none' }} />
          </form>

          <div className="uploaded-files">
            <h2>Uploaded Music</h2>
            {uploadedFiles.length === 0 && <p className='no-music-uploaded'>No music uploaded yet.</p>}
            {uploadedFiles.map((file, index) => (
              <div key={index} className={`file-item ${file.status}`}>
                <div className="file-info">
                {file.status === 'uploading' && 
                <span className="loading-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#3e8cac" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V3C11 2.44772 11.4477 2 12 2Z" fill="currentColor" />
                      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M12 17C12.5523 17 13 17.4477 13 18V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V18C11 17.4477 11.4477 17 12 17Z" fill="currentColor" />
                      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M17 12C17 11.4477 17.4477 11 18 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H18C17.4477 13 17 12.5523 17 12Z" fill="currentColor" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11L6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H3C2.44772 13 2 12.5523 2 12Z" fill="currentColor" />
                      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M19.0706 4.92961C19.4611 5.32014 19.4611 5.9533 19.0706 6.34383L16.9493 8.46515C16.5588 8.85567 15.9256 8.85567 15.5351 8.46515C15.1446 8.07462 15.1446 7.44146 15.5351 7.05093L17.6564 4.92961C18.0469 4.53909 18.6801 4.53909 19.0706 4.92961Z" fill="currentColor" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46515 15.5351C8.85567 15.9256 8.85567 16.5588 8.46515 16.9493L6.34383 19.0706C5.9533 19.4611 5.32014 19.4611 4.92961 19.0706C4.53909 18.6801 4.53909 18.0469 4.92961 17.6564L7.05093 15.5351C7.44146 15.1446 8.07462 15.1446 8.46515 15.5351Z" fill="currentColor" />
                      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M15.5351 15.5351C15.9256 15.1446 16.5588 15.1446 16.9493 15.5351L19.0706 17.6564C19.4611 18.0469 19.4611 18.6801 19.0706 19.0706C18.6801 19.4611 18.0469 19.4611 17.6564 19.0706L15.5351 16.9493C15.1446 16.5588 15.1446 15.9256 15.5351 15.5351Z" fill="currentColor" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.92961 4.92961C5.32014 4.53909 5.9533 4.53909 6.34383 4.92961L8.46515 7.05093C8.85567 7.44146 8.85567 8.07462 8.46515 8.46515C8.07462 8.85567 7.44146 8.85567 7.05093 8.46515L4.92961 6.34383C4.53909 5.9533 4.53909 5.32014 4.92961 4.92961Z" fill="currentColor" />
                  </svg>
                </span>
                }
                {file.status === 'success' && 
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M8 12L11 15L16 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                }
                  <span>{file.name}</span>
                </div>
                <div className="file-status">
                  {file.status === 'uploading' && (
                    <div className="progress-bar">
                      <div 
                        className="progress" 
                        style={{ width: `${file.progress}%`, backgroundColor: '#3e8cac !important' }} 
                      />
                    </div>
                  )}
                  {file.status === 'success' && (
                    <div className="progress-bar">
                      <div 
                        className="progress" 
                        style={{ width: '100%', backgroundColor: '#00CE62' }} 
                      />
                    </div>
                  )}
                  {file.status === 'error' && <span className="status error sr-only">Upload Failed. Try Again</span>}
                  <span className='custom-upload-small-text color-white'>{(file.size / 1024 / 1024).toFixed(2)}Mb</span>
                  <button onClick={() => removeFileHandler(file.name)} className="remove-button" disabled={isDone}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
                      <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button className="done-button" onClick={handleDone}>Done</button>
        </>
        )}
      <p className="terms"><span>Terms & Conditions</span><br /> <span><a style={{ color: '#888888' }}  href="mailto:support@lightenergylabs.xyz">Support</a></span></p>
    </div>
  );
};

export default UploadForm;
